Application.Helper.Gallery = (function($) {

    var unsavedChanges = false;
    var imageListing;

    function init() {

        imageListing = $('.list_images').html();

        applyUnsavedChangesBehavior();
        applyImageSortingBehavior();
        applyImageUploadBehavior();
        applyImageRemovalBehavior();
        applyCheckboxesBehavior();
        applyEditLegendBehavior();
        applyEditTitleBehavior();
    }

    function applyEditLegendBehavior() {

        $('body').on('click', '.close_feedback', function() {
            $feedback.remove();
        });

        $('body').on('click', 'div.create_date a, div.edit_date a', function() {
            $(this).hide();
            $(this).parent().find('input[name=date]').show().focus();
            return false;
        });

        $('body').on('click', 'div.create_legend a, div.edit_legend a', function() {
            $(this).hide();
            $(this).parent().find('input[name=legend]').show().focus();
            return false;
        });

        $('body').on('click', 'div.create_legend_pt a, div.edit_legend_pt a', function() {
            $(this).hide();
            $(this).parent().find('input[name=legendPt]').show().focus();
            return false;
        });

        $('body').on('click', 'div.create_legend_en a, div.edit_legend_en a', function() {
            $(this).hide();
            $(this).parent().find('input[name=legendEn]').show().focus();
            return false;
        });

        $('body').on('click', 'div.create_legend_es a, div.edit_legend_es a', function() {
            $(this).hide();
            $(this).parent().find('input[name=legendEs]').show().focus();
            return false;
        });

        $('body').on('keydown', 'div.create_date input[name=date], div.edit_date input[name=date]', saveDateCallback);
        $('body').on('focusout', 'div.create_date input[name=date], div.edit_date input[name=date]', saveDateCallback);

        $('body').on('keydown', 'div.create_legend input[name=legend], div.edit_legend input[name=legend]', saveLegendCallback);
        $('body').on('focusout', 'div.create_legend input[name=legend], div.edit_legend input[name=legend]', saveLegendCallback);

        $('body').on('keydown', 'div.create_legend_pt input[name=legendPt], div.edit_legend_pt input[name=legendPt]', saveLegendPtCallback);
        $('body').on('focusout', 'div.create_legend_pt input[name=legendPt], div.edit_legend_pt input[name=legendPt]', saveLegendPtCallback);

        $('body').on('keydown', 'div.create_legend_en input[name=legendEn], div.edit_legend_en input[name=legendEn]', saveLegendEnCallback);
        $('body').on('focusout', 'div.create_legend_en input[name=legendEn], div.edit_legend_en input[name=legendEn]', saveLegendEnCallback);

        $('body').on('keydown', 'div.create_legend_es input[name=legendEs], div.edit_legend_es input[name=legendEs]', saveLegendEsCallback);
        $('body').on('focusout', 'div.create_legend_es input[name=legendEs], div.edit_legend_es input[name=legendEs]', saveLegendEsCallback);
    }

    function saveDateCallback(e) {

        if (e.type == 'focusout') {
            if ($(this).is(':hidden')) {
                return;
            }
        } else {
            var code = (e.keyCode ? e.keyCode : e.which);
            if (code != $.ui.keyCode.ENTER) {
                return true;
            }
        }

        var $dateTextInput = $(this);
        var newDate = $(this).val().replace(/^[ \t]+|[ \t]+$/g, '');
        var idImagem = $(this).closest('li[id^=positions_]').attr('id').split('_').pop();
        var updateSubtitleUrl = $(this).closest('li[id^=positions_]').data('update-subtitle-url');

        $.ajax({
            type: 'post',
            url: updateSubtitleUrl,
            data: 'column=date&value=' + newDate,
            success: function() {
                if (newDate.length > 0) {
                    $dateTextInput.closest('li[id^=positions_]').find('.create_date').hide();
                    $dateTextInput.closest('li[id^=positions_]').find('.edit_date')
                        .find('a.date').text(newDate).show().end()
                        .find('input[name=date]').val(newDate).hide().end()
                        .show();
                } else {
                    $dateTextInput.closest('li[id^=positions_]').find('.edit_date').hide();
                    $dateTextInput.closest('li[id^=positions_]').find('.create_date')
                        .find('a').show().end()
                        .find('input[name=date]').val('').hide().end()
                        .show();
                }
            }
        });
    }

    function saveLegendCallback(e) {

        if (e.type == 'focusout') {
            if ($(this).is(':hidden')) {
                return;
            }
        } else {
            var code = (e.keyCode ? e.keyCode : e.which);
            if (code != $.ui.keyCode.ENTER) {
                return true;
            }
        }

        var $legendTextInput = $(this);
        var newLegend = $(this).val().replace(/^[ \t]+|[ \t]+$/g, '');
        var idImagem = $(this).closest('li[id^=positions_]').attr('id').split('_').pop();
        var updateSubtitleUrl = $(this).closest('li[id^=positions_]').data('update-subtitle-url');

        $.ajax({
            type: 'post',
            url: updateSubtitleUrl,
            data: 'column=legend&value=' + newLegend,
            success: function() {
                if (newLegend.length > 0) {
                    $legendTextInput.closest('li[id^=positions_]').find('.create_legend').hide();
                    $legendTextInput.closest('li[id^=positions_]').find('.edit_legend')
                        .find('a.legend').text(newLegend).show().end()
                        .find('input[name=legend]').val(newLegend).hide().end()
                        .show();
                } else {
                    $legendTextInput.closest('li[id^=positions_]').find('.edit_legend').hide();
                    $legendTextInput.closest('li[id^=positions_]').find('.create_legend')
                        .find('a').show().end()
                        .find('input[name=legend]').val('').hide().end()
                        .show();
                }
            }
        });
    }

    function saveLegendPtCallback(e) {

        if (e.type == 'focusout') {
            if ($(this).is(':hidden')) {
                return;
            }
        } else {
            var code = (e.keyCode ? e.keyCode : e.which);
            if (code != $.ui.keyCode.ENTER) {
                return true;
            }
        }

        var $legendPtTextInput = $(this);
        var newLegendPt = $(this).val().replace(/^[ \t]+|[ \t]+$/g, '');
        var idImagem = $(this).closest('li[id^=positions_]').attr('id').split('_').pop();
        var updateSubtitleUrl = $(this).closest('li[id^=positions_]').data('update-subtitle-url');

        $.ajax({
            type: 'post',
            url: updateSubtitleUrl,
            data: 'column=legendPt&value=' + newLegendPt,
            success: function() {
                if (newLegendPt.length > 0) {
                    $legendPtTextInput.closest('li[id^=positions_]').find('.create_legend_pt').hide();
                    $legendPtTextInput.closest('li[id^=positions_]').find('.edit_legend_pt')
                        .find('a.legend_pt').text(newLegendPt).show().end()
                        .find('input[name=legendPt]').val(newLegendPt).hide().end()
                        .show();
                } else {
                    $legendPtTextInput.closest('li[id^=positions_]').find('.edit_legend_pt').hide();
                    $legendPtTextInput.closest('li[id^=positions_]').find('.create_legend_pt')
                        .find('a').show().end()
                        .find('input[name=legendPt]').val('').hide().end()
                        .show();
                }
            }
        });
    }

    function saveLegendEnCallback(e) {

        if (e.type == 'focusout') {
            if ($(this).is(':hidden')) {
                return;
            }
        } else {
            var code = (e.keyCode ? e.keyCode : e.which);
            if (code != $.ui.keyCode.ENTER) {
                return true;
            }
        }

        var $legendEnTextInput = $(this);
        var newLegendEn = $(this).val().replace(/^[ \t]+|[ \t]+$/g, '');
        var idImagem = $(this).closest('li[id^=positions_]').attr('id').split('_').pop();
        var updateSubtitleUrl = $(this).closest('li[id^=positions_]').data('update-subtitle-url');

        $.ajax({
            type: 'post',
            url: updateSubtitleUrl,
            data: 'column=legendEn&value=' + newLegendEn,
            success: function() {
                if (newLegendEn.length > 0) {
                    $legendEnTextInput.closest('li[id^=positions_]').find('.create_legend_en').hide();
                    $legendEnTextInput.closest('li[id^=positions_]').find('.edit_legend_en')
                        .find('a.legend_en').text(newLegendEn).show().end()
                        .find('input[name=legendEn]').val(newLegendEn).hide().end()
                        .show();
                } else {
                    $legendEnTextInput.closest('li[id^=positions_]').find('.edit_legend_en').hide();
                    $legendEnTextInput.closest('li[id^=positions_]').find('.create_legend_en')
                        .find('a').show().end()
                        .find('input[name=legendEn]').val('').hide().end()
                        .show();
                }
            }
        });
    }

    function saveLegendEsCallback(e) {

        if (e.type == 'focusout') {
            if ($(this).is(':hidden')) {
                return;
            }
        } else {
            var code = (e.keyCode ? e.keyCode : e.which);
            if (code != $.ui.keyCode.ENTER) {
                return true;
            }
        }

        var $legendEsTextInput = $(this);
        var newLegendEs = $(this).val().replace(/^[ \t]+|[ \t]+$/g, '');
        var idImagem = $(this).closest('li[id^=positions_]').attr('id').split('_').pop();
        var updateSubtitleUrl = $(this).closest('li[id^=positions_]').data('update-subtitle-url');

        $.ajax({
            type: 'post',
            url: updateSubtitleUrl,
            data: 'column=legendEs&value=' + newLegendEs,
            success: function() {
                if (newLegendEs.length > 0) {
                    $legendEsTextInput.closest('li[id^=positions_]').find('.create_legend_es').hide();
                    $legendEsTextInput.closest('li[id^=positions_]').find('.edit_legend_es')
                        .find('a.legend_es').text(newLegendEs).show().end()
                        .find('input[name=legendEs]').val(newLegendEs).hide().end()
                        .show();
                } else {
                    $legendEsTextInput.closest('li[id^=positions_]').find('.edit_legend_es').hide();
                    $legendEsTextInput.closest('li[id^=positions_]').find('.create_legend_es')
                        .find('a').show().end()
                        .find('input[name=legendEs]').val('').hide().end()
                        .show();
                }
            }
        });
    }


    function applyEditTitleBehavior() {

        $('body').on('click', '.close_feedback', function() {
            $feedback.remove();
        });

        $('body').on('click', 'div.create_title a, div.edit_title a', function() {
            $(this).hide();
            $(this).parent().find('input[name=title]').show().focus();
            return false;
        });

        $('body').on('keydown', 'div.create_title input[name=title], div.edit_title input[name=title]', saveTitleCallback);
        $('body').on('focusout', 'div.create_title input[name=title], div.edit_title input[name=title]', saveTitleCallback);
    }

    function saveTitleCallback(e) {

        if (e.type == 'focusout') {
            if ($(this).is(':hidden')) {
                return;
            }
        } else {
            var code = (e.keyCode ? e.keyCode : e.which);
            if (code != $.ui.keyCode.ENTER) {
                return true;
            }
        }

        var $titleTextInput = $(this);
        var newTitle = $(this).val().replace(/^[ \t]+|[ \t]+$/g, '');
        var idImagem = $(this).closest('li[id^=positions_]').attr('id').split('_').pop();
        var updateSubtitleUrl = $(this).closest('li[id^=positions_]').data('update-subtitle-url');

        $.ajax({
            type: 'post',
            url: updateSubtitleUrl,
            data: 'column=title&value=' + newTitle,
            success: function() {
                if (newTitle.length > 0) {
                    $titleTextInput.closest('li[id^=positions_]').find('.create_title').hide();
                    $titleTextInput.closest('li[id^=positions_]').find('.edit_title')
                        .find('a.title').text(newTitle).show().end()
                        .find('input[name=title]').val(newTitle).hide().end()
                        .show();
                } else {
                    $titleTextInput.closest('li[id^=positions_]').find('.edit_title').hide();
                    $titleTextInput.closest('li[id^=positions_]').find('.create_title')
                        .find('a').show().end()
                        .find('input[name=title]').val('').hide().end()
                        .show();
                }
            }
        });
    }

    function applyCheckboxesBehavior() {

        $('.check_all').click(function() {
            $('input[name=ids\\[\\]]').each(function() {
                $(this).closest('.image_container').addClass('selected');
                $(this).attr('checked', true);
            });
            return false;
        });

        $('.uncheck_all').click(function() {
            $('input[name=ids\\[\\]]').each(function() {
                $(this).attr('checked', false);
                $(this).closest('.image_container').removeClass('selected');
            });
            return false;
        });

        $('body').on('change', 'input[name=ids\\[\\]]', function() {
            if ($(this).is(':checked')) {
                $(this).closest('.image_container').addClass('selected');
            } else {
                $(this).closest('.image_container').removeClass('selected');
            }
        });
    }

    function applyUnsavedChangesBehavior() {

        window.onbeforeunload = function(e) {
            var mensagem = 'Há alterações não salvas nesta página. Realmente quero mudar a página?';
            if (unsavedChanges) {
                if ($.browser.msie) {
                    e = window.event;
                }
                if (e) {
                    e.returnValue = mensagem;
                }
            }
        };
    }

    function applyImageSortingBehavior() {

        $('.list_images').sortable({
            cursor: 'move',
            opacity: '0.5',
            update: function(event, ui) {
                $('.save_sorting').show();
                $('.cancel_sorting').show();
                $('input[name=file]').hide();
                $('.save_sorting_before_uploading').show();
                unsavedChanges = true;
            }
        });

        $('.save_sorting').click(function() {
            $.ajax({
                type: 'post',
                url: $(this).data('action-url'),
                data: $('.list_images').sortable('serialize'),
                success: function() {
                    $('.save_sorting').hide();
                    $('.cancel_sorting').hide();
                    $('input[name=file]').show();
                    $('.save_sorting_before_uploading').hide();
                    imageListing = $('.list_images').html();
                    unsavedChanges = false;
                }, error: function() {
                    $('.save_sorting').hide();
                    $('.cancel_sorting').hide();
                    $('input[name=file]').show();
                    $('.save_sorting_before_uploading').hide();
                    unsavedChanges = false;
                }
            });
            return false;
        });

        $('.cancel_sorting').click(function() {
            $('.list_images').html(imageListing);
            $('.save_sorting').hide();
            $('.cancel_sorting').hide();
            $('input[name=file]').show();
            $('.save_sorting_before_uploading').hide();
            unsavedChanges = false;
        });
    }

    function applyImageUploadBehavior() {

        $('#errors_report .erase_and_close').click(function() {
            $(this).prev('.errors').html('');
            $(this).parent().hide();
        });

        if ($('div.dropzone').length < 1) {
            return;
        }

        var recordId = $('input[name=id]').val();

        $("div.dropzone").dropzone({
            acceptedFiles: '.jpeg, .jpg, .png',
            url: $('div.dropzone').data('action-url'),
            addRemoveLinks: false,
            createImageThumbnails: false,
            init: function() {
                this.on("complete", function(file) {
                    Application.UI.showMessage({ type: 'success', message: 'Operação realizada com sucesso.' });

                    var thumbnails = $.parseJSON(file.xhr.responseText)["thumbnail"];

                    var i;
                    var length = thumbnails.length;

                    for (i = 0; i < length; i++) {

                        // adiciona a nova imagem
                        $('.list_images').append(
                            '<li id="positions_'+thumbnails[i].id+'" data-update-subtitle-url="'+thumbnails[i].updateSubtitleUrl+'">'+
                            '    <span class="image_container">'+
                            '        <img src="'+thumbnails[i].url+'"></img>'+
                            '        <input type="checkbox" name="ids[]" value="'+thumbnails[i].id+'" class="prevent_focus" />'+
                            '    </span>'+
                            '    <div class="create_legend">'+
                            '        <a href="javascript:;" class="add_legend">'+(thumbnails[i].legend ? thumbnails[i].legend : 'Adicionar legenda')+'</a>'+
                            '        <input type="text" name="legend" value="" style="display:none;" />'+
                            '    </div>'+
                            '    <div class="edit_legend" style="display:none">'+
                            '        <a href="javascript:;" class="legend"></a>'+
                            '        <input type="text" name="legend" value="" style="display:none;" />'+
                            '    </div>'+
                            // '    <div class="create_date">'+
                            // '        <a href="javascript:;" class="add_date">'+(thumbnails[i].date ? thumbnails[i].date : 'Adicionar data')+'</a>'+
                            // '        <input type="text" name="date" value="" style="display:none;" />'+
                            // '    </div>'+
                            // '    <div class="edit_date" style="display:none">'+
                            // '        <a href="javascript:;" class="date"></a>'+
                            // '        <input type="text" name="date" value="" style="display:none;" />'+
                            // '    </div>'+
                            '</li>'
                        );

                    }

                    // atualiza o total de imagens já existentes
                    $('.total_images').text($('.list_images li').length);

                    // define a nova listagem original
                    originalImageListing = $('.list_images').html();

                    // caso tenha sido escondido por uma ação de remoção de imagens
                    $('.edit_images').show();
                });
                this.on("reset", function() {
                    $('.dropzone').next('.submit').hide();
                });
            }
        });
    }

    function applyImageRemovalBehavior() {

        $(document).click(function(e) {
            var $target = $(e.target);
            if ($target.parents().is('.list_images') && e.target.tagName != 'INPUT' && e.target.tagName != 'A') {
                if ($target.is('li')) {
                    var $checkbox = $target.find(':checkbox');
                } else {
                    var $checkbox = $target.parents('li').find(':checkbox');
                }
                $checkbox.click();
            }
        });

        $('.remove_selected').click(function() {
            var $ids = $('input[name=ids\\[\\]]');
            if ($ids.filter(':checked').length == 0) {
                return false;
            }
            if (!confirm($(this).data('details'))) {
                return false;
            }
            $form = $(this);
            $.ajax({
                type: 'post',
                url: $(this).data('action-url'),
                data: $ids.serialize(),
                success: function() {
                    $ids.filter(':checked').each(function() {
                        $(this).closest('li[id^=positions_]').remove();
                    });
                    var totalImages = $('input[name=ids\\[\\]]').length;
                    if (totalImages == 0) {
                        $('.edit_images').hide();
                    }
                    imageListing = $('.list_images').html();
                    $('.total_images').text(totalImages);
                }
            });
            return false;
        });
    }

    return {
        'init': init
    };
})(jQuery);
